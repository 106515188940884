import { htmlId } from "@rpforms/shared/build/utils";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { FormHeader, FormLandscape, FormPortrait, FormTitle } from "./FormPresenter";
const logoPath = require("../images/logo.svg");
const FormContainer = styled.div<{ isPrinting?: boolean }>`
    ${(props) =>
        props.isPrinting &&
        `
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        
        * {
            color: #000 !important;
        }
        
        * {
            background-color: transparent !important;
            border-color: #000 !important;
        }
        
        svg {
            fill: #000 !important;
            stroke: #000 !important;
        }
    `}

    [data-field="Header"] {
        width: 100%;
        padding: 0 20px;
        img {
            height: 100px !important;
        }
    }

    .sheet {
        min-height: calc(297mm - 120px);
    }

    .portrait .sheet {
        padding-left: 1.5cm;
        padding-right: 1.5cm;
        min-height: calc(297mm - 240px);
    }

    [data-field="Footer"] {
        img {
            height: 40px;
        }
    }
`;

const Fold = styled.div`
    width: 0.5cm;
    height: 1px;
    background-color: #000;
    left: 0;
    position: absolute;
    ${(p: any) => p.top && `top: 105mm;`}
    ${(p: any) => p.bottom && `top: 210mm;`}
`;

/**
 * Presents a schemaBuilder in a way that users can submit entries
 * This does not store any submitted forms as they are processed by an external URL
 * By not handling the form submission (other than basic validation) we ensure
 * that powerform can be embedded into any existing webpage and even
 * replace forms with it without fiddling with the backend.
 *
 * @param form
 * @param presenter
 * @param schema {any} The schemaBuilder you want to present to the user
 * @param layout
 * @param saveState
 * @param isPrinting
 * @constructor
 */
export const PDFPresenter = ({ form, schema, layout, saveState, isPrinting }) => {
    const formID = form.name.split("_")[0];
    let formName = form.name.replace(formID + "_", "");
    const fields = useMemo(() => {
        return schema.fields();
    }, []);

    useEffect(() => {
        fields.map((field) => {
            if (field.wasRendered) {
                field.wasRendered();
            }
        });
    }, []);

    (window as any).__GLOBAL_CONDITIONALS = true;
    (window as any).__GLOBAL_RULE_EVALUATION_MAP = {};

    const config = { layout: FormPortrait, isLandscape: false };
    if (formID === "01") {
        config.isLandscape = true;
        config.layout = FormLandscape;
    }

    const cssPrintPage = {
        [FormPortrait]: `@page { size: A4; }
            @page {
        margin: 10mm 0mm 40mm 0mm;
    }

    @page:first {
        margin: 0mm 0mm 40mm 0mm !important;
    }

    nav { display: none !important; }`,
        [FormLandscape]: `@page { size: A4 landscape; }     @page {
        margin: 10mm 0mm 10mm 0mm;
    }

    @page:first {
        margin: 0mm 0mm 10mm 0mm !important;
    }
     nav { display: none !important; }`,
    };

    const styleSheet = document.createElement("style");
    styleSheet.innerHTML = cssPrintPage[config.layout];
    document.body.appendChild(styleSheet);
    const sheets = schema.asPDFSheets();
    useEffect(() => {
        document.body.classList.add("A4");
        if (config.isLandscape) {
            document.body.classList.add("landscape");
        }
        return () => {
            document.body.classList.remove("A4");
            document.body.classList.remove("landscape");
        };
    });

    if (schema.getConfig()) {
        formName = schema.getConfig().title;
    }

    let variables = {};
    (window as any).__GLOBAL_CONDITIONALS = true;

    const hasFooter = !!schema.fields().find((field) => field.fieldType === "Footer");
    const hasHeader = !!schema.fields().find((field) => field.fieldType === "Header");

    return (
        <>
            <FormContainer
                className="form-container"
                id={htmlId({ id: formID })}
                data-form={formID}
                isPrinting={isPrinting}
            >
                <div style={{ paddingBottom: 0, padding: 0, margin: 0 }}>
                    <div className={`print-area ${config.isLandscape ? "landscape" : "portrait"}`}>
                        {sheets.map((sheet, index) => {
                            return (
                                <div className="sheet" key={`sheet_${index}`}>
                                    <Fold top />
                                    <Fold bottom />
                                    {index === 0 && !hasHeader && (
                                        <FormHeader data-header style={{ margin: 0 }}>
                                            <FormTitle data-title>
                                                {formName}
                                                <br />
                                            </FormTitle>
                                            <img data-logo src={logoPath} />
                                        </FormHeader>
                                    )}

                                    <div
                                        data-sheet
                                        style={{
                                            paddingTop: "15px",
                                            display: "flex",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        {sheet.map((field: any) => {
                                            if (!saveState) {
                                                return <div key={"sheet-" + field.hashCode}></div>;
                                            }
                                            if (isPrinting) {
                                                variables = { ...variables, isPrinting: true };
                                            }
                                            field.formName = htmlId({ id: formID });
                                            return (
                                                <field.WrapComponent
                                                    layout={layout}
                                                    data-field-wrapper
                                                    key={field.hashCode}
                                                    field={field}
                                                    entry={null}
                                                    component={
                                                        <div data-field={field.fieldType}>
                                                            <field.InputComponent
                                                                variables={variables}
                                                                data-input-component
                                                                layout={layout}
                                                                register={{}}
                                                                schema={schema}
                                                                field={field}
                                                                entry={null}
                                                                rawValue={field.rawValue}
                                                                saveState={saveState}
                                                            />
                                                        </div>
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                    {sheets.length === index + 1 && !hasFooter && (
                                        <div
                                            className={"rpfe-footnote"}
                                            style={{ position: "relative" }}
                                        >
                                            Die Prüfung wurde nach bestem Wissen und Gewissen gemäß
                                            Herstellerangaben einschließlich der gültigen EN und DIN
                                            durchgeführt. Für versteckte Mängel, die bei der
                                            Anwendung der erforderlichen Sorgfalt nicht zu erkennen
                                            sind, wird eine Haftung ausgeschlossen. Weitere Hinweise
                                            entnehmen Sie dem Prüfbuch, dem dieses Prüfprotokoll
                                            beizufügen ist. Das Prüfbuch ist, sofern nötig, vom
                                            Betreiber zu pflegen und aufzubewahren und auf Verlangen
                                            bereitzuhalten.
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </FormContainer>
        </>
    );
};
