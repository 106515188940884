import {
    createCalendarEvent,
    deleteCalendarEvent,
    NEW_UPLOAD,
    toSelectOptions,
} from "@rpforms/shared";
import {
    getCalendarEventById,
    getDevices,
    updateCalendarEvent,
} from "@rpforms/shared/build/actions";
import { getCalendarEventParticipants } from "@rpforms/shared/build/actions/calendar_event_participants";
import { openEditor } from "@rpforms/shared/build/actions/editor";
import { fetchEmployees } from "@rpforms/shared/build/actions/employees";
import { fetchDeviceTasks } from "@rpforms/shared/build/actions/tasks";
import { SmallDangerButton } from "@rpforms/shared/build/components/Button";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import { Loader } from "@rpforms/shared/build/components/universal/Loader";
import PageNavAction from "@rpforms/shared/build/components/universal/PageNavAction";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import { push } from "connected-react-router";
import moment from "moment";
import TimePicker from "rc-time-picker";
import React, { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSearchParam } from "react-use";
import AddDeviceTask from "../../../components/devices/calendar_events/AddDeviceTask";
import { ListDeviceTasks } from "../../../components/devices/calendar_events/ListDeviceTasks";
import { RootState } from "../../../reducers";
import { DeviceSelector } from "./DeviceSelector";
import { RepairBox } from "./RepairBox";
import { AsyncEntitySelect } from "../../../components/devices/calendar_events/AsyncEntitySelect";

const ViewCalendarEventPage = () => {
    const { id: deviceId, eventId }: { id: string; eventId: string } = useParams();
    const d = useSearchParam("d");
    const e = useSearchParam("e");
    const isSubmittedEvent = useLocation().search === "?submitted=true";
    const orderedMaterial = useLocation().search === "?material=true";
    const calendarEvent = useSelector<any, any>((state) =>
        state.calendarEvents.detailedEvents.find((c) => c.id === parseInt(eventId))
    );
    const events = useSelector<RootState, any>((store) => store.calendarEvents.detailedEvents);
    const employees = useSelector<RootState, any>((store) => store.employees.employees);
    const allParticipants = useSelector<RootState, any>(
        (store) => store.calendarEventParticipants.participants
    );

    const [notes, setNotes] = useState("");
    const [showEntities, setShowEntities] = useState(false);
    const [eventType, setEventType] = useState<any>(null);
    const [startingTime, setStartingTime] = useState(eventId ? null : "10:00"); // should nt
    const [endingTime, setEndingTime] = useState(eventId ? null : "12:00");
    const [participants, setParticipants] = useState<any>([]);
    const [location, setLocation] = useState("");
    const [phone, setPhone] = useState(null);
    const [editReady, setEditReady] = useState(false);
    const [contactPerson, setContactPerson] = useState(null);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [selectableDevices, setSelectableDevices] = useState(null);
    const [event, setEvent] = useState(null);
    const tasks = useSelector<any, any>((state) => state.calendarEvents.tasks);
    const loading = useSelector<any, any>((state) => state.calendarEvents.isLoading);
    const devices = useSelector<any, any>((state) => state.device.devices);
    const fetchedEvent = events.find((e) => e.id === parseInt(eventId, 10));
    const [updateNameNextCycle, setUpdateNameNextCycle] = useState(false);
    const [focusedInput, setFocusedInput] = useState();
    const [startDateTime, setStartDate] = useState(d ? moment(d) : null);
    const [endDateTime, setEndDate] = useState(e ? moment(e) : null);
    const [materialNotes, setMaterialNotes] = useState("");
    const [name, setName] = useState("");
    const [repairBox, setRepairBox] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [showMaterialAlert, setShowMaterialAlert] = useState(orderedMaterial);
    const [saveNextCycle, setSaveNextCycle] = useState(false);
    const dispatch = useDispatch();

    const isNachbearbeitung = eventType?.name === "Nachbearbeitung";
    const isRolandPrivat = eventType?.name === "Roland Privat";

    useEffect(() => {
        if (devices.length > 0) {
            setSelectableDevices(() => toSelectOptions(devices));
        }
        if (deviceId) {
            setSelectedDevice(devices.find((device) => device.id === parseInt(deviceId, 10)));
        }
    }, [devices, deviceId]);

    useEffect(() => {
        if (!eventId || devices.length === 0) {
            return;
        }
        const type = fetchedEvent?.type;
        setEvent(() => fetchedEvent);
        setEventType(() => type);
        if (type?.name === "Seminar") {
            dispatch(getCalendarEventParticipants());
        }

        setName(() => {
            return fetchedEvent?.name !== null ? fetchedEvent?.name : "";
        });

        setEditReady(true);

        setNotes(() => fetchedEvent?.notes);
        setStartDate(() => moment(fetchedEvent?.scheduled_from));
        setEndDate(() => moment(fetchedEvent?.scheduled_to));
        setShowEntities(() => !!fetchedEvent?.entity_id);
        if (moment(fetchedEvent?.scheduled_from).hours() > 0) {
            setStartingTime(() => moment(fetchedEvent?.scheduled_from).format("HH:mm"));
        }

        if (moment(fetchedEvent?.scheduled_to).hours() > 0) {
            setEndingTime(() => moment(fetchedEvent?.scheduled_to).format("HH:mm"));
        }

        if (!deviceId) {
            setSelectedDevice(() =>
                devices.find((device) => device.id === fetchedEvent?.device_id)
            );
        }
        setLocation(() => fetchedEvent?.location);
        setPhone(() => fetchedEvent?.phone);
        setContactPerson(() => fetchedEvent?.contactperson);
    }, [deviceId, events, devices]);

    useEffect(() => {
        if (!eventType) {
            return;
        }
        if (eventType?.name === "Seminar" && employees.length === 0) {
            dispatch(fetchEmployees());
        }
    }, [eventType]);

    useEffect(() => {
        if (
            allParticipants.length > 0 &&
            event &&
            eventType?.name === "Seminar" &&
            employees.length > 0
        ) {
            const eventParticipants = allParticipants
                .filter((p) => p.calendar_event_id === event.id)
                .map((p) => p.employee_id);
            setParticipants(() =>
                toSelectOptions(employees.filter((emp) => eventParticipants.includes(emp.id)))
            );
        }
    }, [allParticipants, event, eventType, employees]);

    useEffect(() => {
        dispatch(getDevices({ forSelect: true }));
        if (!events.find((e) => e.id === parseInt(eventId))) {
            dispatch(getCalendarEventById(eventId));
        }
        isSubmittedEvent
            ? dispatch(fetchDeviceTasks(eventId, false, true, true))
            : dispatch(fetchDeviceTasks(eventId, false, false, true));
    }, [deviceId, eventId, events, isSubmittedEvent]);

    const onEntitiesLoaded = (entities) => {
        return entities.map((xEntity) => {
            if (fetchedEvent?.entity_id === xEntity.id) {
                setSelectedEntity({
                    value: xEntity.id,
                    label: xEntity.name,
                });
            }
        });
    };
    useEffect(() => {
        window.moment = moment;
        if (orderedMaterial) {
            if (!calendarEvent?.folder_id) {
                return;
            }
            dispatch(
                openEditor({
                    endpoint: `folders/${calendarEvent.folder_id}/upload`,
                    endpointCompany: false,
                    new: true,
                    preload: { folder: null, title: "Lieferschein hochladen" },
                    type: NEW_UPLOAD,
                })
            );
        }
    }, [loading]);

    useEffect(() => {
        if (updateNameNextCycle) {
            setName(
                tasks
                    .reduce((acc, dt) => {
                        return acc.includes(dt.entity_name) ? acc : [...acc, dt.entity_name];
                    }, [])
                    .join(", ")
            );
            setUpdateNameNextCycle(false);
        }
    }, [tasks]);
    const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";

    const onSaveNewClick = async () => {
        if (name?.length === 0) {
            onGenerateNameClick();
        }

        const scheduledFrom = moment(
            moment(startDateTime).format("YYYY-MM-DD") + (startingTime ? startingTime : "00:00"),
            "YYYY-MM-DDhh:mm"
        ).format(dateFormat);

        const scheduledTo = moment(
            moment(endDateTime).format("YYYY-MM-DD") + (endingTime ? endingTime : "00:00"),
            "YYYY-MM-DDhh:mm"
        ).format(dateFormat);

        const params = {
            ...calendarEvent,
            notes,
            location,
            calendar_event_type_id: eventType?.id,
            participants: participants.length > 0 ? participants.map((p) => p.value) : null,
            phone,
            contactperson: contactPerson,
            rep_text: (window as any).reparea ? (window as any).reparea.value : null,
            name: name?.length > 0 ? name : tasks.map((dt) => dt.entity_name).join(", "),
            scheduled_from: scheduledFrom,
            scheduled_to: scheduledTo,
            needs_material: false,
            entity_id: selectedEntity?.value || null,
            device_id:
                deviceId === "null" ? selectedDevice?.id || null : deviceId || selectedDevice.id,
        };

        if (orderedMaterial) {
            if (!endDateTime) {
                missingDateToast();
                return;
            }
            if (!materialNotes) {
                missingNotesToast();
                return;
            }
            if (!selectedDevice) {
                missingDeviceToast();
                return;
            }
        }
        await dispatch(
            updateCalendarEvent(params, async () => {
                await onCreateClick();
                toast.success("✌️ Event gespeichert");
            })
        );
    };

    const onCreateClick = async () => {
        const scheduledFrom = moment(
            moment(startDateTime).format("YYYY-MM-DD") + (startingTime ? startingTime : "00:00"),
            "YYYY-MM-DDhh:mm"
        ).format(dateFormat);
        const scheduledTo = moment(
            moment(endDateTime).format("YYYY-MM-DD") + (endingTime ? endingTime : "00:00"),
            "YYYY-MM-DDhh:mm"
        ).format(dateFormat);

        if (!eventType || eventType?.name === "Ausführung" || eventType?.name === "Besichtigung") {
            const eventParams = {
                scheduled_from: scheduledFrom,
                scheduled_to: scheduledTo,
                device_id: selectedDevice?.id,
                calendar_event_type_id: eventType?.id,
            };
            await dispatch(createCalendarEvent(eventParams, false, true));
            return;
        }

        const params = {
            notes: "",
            name: "",
            location: "",
            scheduled_from: scheduledFrom,
            scheduled_to: scheduledTo,
            calendar_event_type_id: eventType?.id,
            participants: null,
            phone: "",
            contactperson: "",
            entity_id: null,
            device_id: selectedDevice?.id,
        };
        await dispatch(createCalendarEvent(params, false, true));
        return;
    };

    const onSaveClick = async () => {
        if (!startDateTime || !endDateTime) {
            missingDateToast();
            return;
        }

        if (name?.length === 0) {
            onGenerateNameClick();
        }

        const scheduledFrom = moment(
            moment(startDateTime).format("YYYY-MM-DD") + (startingTime ? startingTime : "00:00"),
            "YYYY-MM-DDhh:mm"
        ).format(dateFormat);
        const scheduledTo = moment(
            moment(endDateTime).format("YYYY-MM-DD") + (endingTime ? endingTime : "00:00"),
            "YYYY-MM-DDhh:mm"
        ).format(dateFormat);

        const params = {
            ...calendarEvent,
            rep_text: (window as any).reparea ? (window as any).reparea.value : null,
            name: name?.length > 0 ? name : tasks.map((dt) => dt.entity_name).join(", "),
            device_id: selectedDevice?.id,
            notes,
            scheduled_from: scheduledFrom,
            needs_material: false,
            scheduled_to: scheduledTo,
            location,
            calendar_event_type_id: eventType?.id,
            participants: participants.length > 0 ? participants.map((p) => p.value) : null,
            phone,
            contactperson: contactPerson,
            entity_id: selectedEntity?.value || null,
        };

        if (orderedMaterial) {
            if (!endDateTime) {
                missingDateToast();
                return;
            }
            if (!materialNotes) {
                missingNotesToast();
                return;
            }
            if (!selectedDevice) {
                missingDeviceToast();
                return;
            }
        }
        await dispatch(updateCalendarEvent(params));
        toast.success("✌️ Event gespeichert", { autoClose: 2000 });
    };

    useEffect(() => {
        if (calendarEvent && calendarEvent.reptext) {
            setRepairBox(true);
        }
    }, [calendarEvent]);

    useEffect(() => {
        if (isRolandPrivat) {
            setSelectedDevice(devices.find((dev) => dev.id === 12));
        }
    }, [eventType]);

    const onDeleteClick = async () => {
        await dispatch(deleteCalendarEvent(calendarEvent));
        toast.success("✌️ Event gelöscht", { autoClose: 2000 });
        dispatch(push("/calendar"));
    };

    const onGenerateNameClick = () => {
        setUpdateNameNextCycle(true);
    };

    useEffect(() => {
        if (saveNextCycle && !updateNameNextCycle) {
            setSaveNextCycle(false);
            onSaveClick();
        }
    }, [saveNextCycle, updateNameNextCycle]);
    const missingDateToast = () => toast.error("Bitte Zeitraum festlegen", { autoClose: 2000 });
    const missingNotesToast = () =>
        toast.error("Bitte Materialinformationen hinzufügen", { autoClose: 2000 });
    const missingDeviceToast = () =>
        toast.error("Bitte das Event einem Gerät zuweisen", { autoClose: 2000 });

    if (!fetchedEvent || !events || !calendarEvent || !tasks || loading || !devices) {
        return <Loader />;
    }

    if (eventId && (!editReady || !startingTime || !endingTime)) {
        return <Loader />;
    }

    const handleEmployeeSelectChange = (option) => {
        if (participants.map((p) => p.value).includes(option.value)) {
            return;
        }
        setParticipants([...participants, option]);
    };

    const handleParticipantDelete = (participant) => {
        setParticipants(participants.filter((p) => p.value !== participant.value));
    };

    return (
        <>
            <PageNavbar
                title="Kalenderevent"
                description="Hier können Sie ein Kalenderevent einsehen und bearbeiten."
            >
                {!calendarEvent.was_submitted && (
                    <PageNavAction
                        onClick={onSaveClick}
                        success={true}
                        icon="save"
                        title="Speichern"
                    />
                )}
                <PageNavAction
                    onClick={() => (window.location.href = "/calendar")}
                    icon="chevron-left"
                    title="Zurück"
                />
                <PageNavAction
                    onClick={onDeleteClick}
                    danger={true}
                    icon="trash"
                    title="Event löschen"
                />
                {!calendarEvent.was_submitted && (
                    <PageNavAction
                        onClick={onSaveNewClick}
                        success={true}
                        icon="save"
                        title="Speichern & neues Event erstellen"
                    />
                )}
            </PageNavbar>
            <Content>
                {!isNachbearbeitung && (
                    <>
                        <br />
                        <Row className="">
                            <Col xs={3}>
                                <label>Datum</label>
                                <div>
                                    <label>Zeitraum</label>
                                    <DateRangePicker
                                        disabled={calendarEvent.was_submitted}
                                        startDate={startDateTime ? moment(startDateTime) : null}
                                        endDate={endDateTime ? moment(endDateTime) : null}
                                        startDateId="scheduled_from" // PropTypes.string.isRequired,
                                        endDateId="scheduled_to" // PropTypes.string.isRequired,
                                        minimumNights={0}
                                        // singleDateRange={true}
                                        startDatePlaceholderText={moment(
                                            calendarEvent.scheduled_from
                                        ).format("DD.MM.YYYY")}
                                        onDatesChange={({ startDate, endDate }) => {
                                            setStartDate(startDate);
                                            setEndDate(endDate);
                                        }} // PropTypes.func.isRequired,
                                        displayFormat={"DD.MM.YYYY"}
                                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={(xFocusedInput) =>
                                            setFocusedInput(xFocusedInput)
                                        } // PropTypes.func.isRequired,
                                    />
                                </div>
                            </Col>
                            <Col xs={4}>
                                <label>Uhrzeit</label>
                                <div>
                                    <TimePicker
                                        value={moment(startingTime, "HH:mm")}
                                        showSecond={false}
                                        minuteStep={15}
                                        onChange={(value) => {
                                            if (value) {
                                                setStartingTime(value.format("HH:mm"));
                                            }
                                        }}
                                    />
                                    <span className="px-2">➜</span>
                                    <TimePicker
                                        value={moment(endingTime, "HH:mm")}
                                        showSecond={false}
                                        minuteStep={15}
                                        onChange={(value) => {
                                            if (value) {
                                                setEndingTime(value.format("HH:mm"));
                                            }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <br />
                    </>
                )}
                {selectableDevices && (
                    <DeviceSelector
                        selectableDevices={selectableDevices}
                        devices={devices}
                        selectedDevice={selectedDevice}
                        setSelectedDevice={setSelectedDevice}
                    />
                )}
                <br />
                {!isRolandPrivat &&
                    eventType?.name !== "Ausführung" &&
                    eventType?.name !== "Besichtigung" &&
                    !isNachbearbeitung && (
                        <div>
                            <br />
                            <label>Objekt</label>
                            <br />
                            <ButtonGroup className="mb-1">
                                <Button
                                    style={{
                                        backgroundColor: !showEntities ? "#2196f3" : "#FFF",
                                        color: !showEntities ? "#FFF" : "#2196f3",
                                    }}
                                    onClick={() => {
                                        setShowEntities(false);
                                        setSelectedEntity(null);
                                    }}
                                    variant="outline-primary"
                                >
                                    Neuer Ort
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: showEntities ? "#2196f3" : "#FFF",
                                        color: showEntities ? "#FFF" : "#2196f3",
                                    }}
                                    onClick={() => {
                                        setShowEntities(true);
                                        setLocation(null);
                                    }}
                                    variant="outline-primary"
                                >
                                    Existierendes Objekt
                                </Button>
                            </ButtonGroup>
                            <br />
                            {showEntities ? (
                                <Row>
                                    <Col xs={6}>
                                        <AsyncEntitySelect
                                            value={selectedEntity}
                                            onChange={(opt) => {
                                                setSelectedEntity(opt);
                                            }}
                                            loading={loading}
                                            onLoad={onEntitiesLoaded}
                                        />
                                    </Col>
                                </Row>
                            ) : (
                                <input
                                    type={"text"}
                                    className="form-control rounded-0"
                                    style={{ width: "50%" }}
                                    defaultValue={location}
                                    onChange={(ev) => setLocation(ev.target.value)}
                                />
                            )}

                            {eventType?.name === "Seminar" && (
                                <>
                                    <br />
                                    <label>Mitarbeiter hinzufügen</label>
                                    <Row>
                                        <Col xs={6}>
                                            <Select
                                                style={{ width: "50%" }}
                                                options={toSelectOptions(employees)}
                                                isSearchable
                                                onChange={(option) =>
                                                    handleEmployeeSelectChange(option)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <br />
                                    <label>Teilnehmende Mitarbeiter ({participants.length})</label>
                                    <Row>
                                        <Col xs={6}>
                                            {participants.map((participant) => (
                                                <div
                                                    className="card mt-2 p-1"
                                                    key={participant.label}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <SmallDangerButton
                                                            onClick={() =>
                                                                handleParticipantDelete(participant)
                                                            }
                                                        >
                                                            <i className={"fa fa-trash"}></i>
                                                        </SmallDangerButton>
                                                        <span className="pl-3">
                                                            {participant.label}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {eventType?.name !== "Besichtigung" && (
                                <div>
                                    <br />
                                    <Row>
                                        <Col xs={3}>
                                            <div>
                                                <label>Ansprechpartner</label>
                                            </div>
                                            <input
                                                type={"text"}
                                                className="form-control rounded-0"
                                                defaultValue={contactPerson}
                                                onChange={(ev) => setContactPerson(ev.target.value)}
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <label>Telefonnummer</label>
                                            </div>
                                            <input
                                                type={"text"}
                                                className="form-control rounded-0"
                                                defaultValue={phone}
                                                onChange={(ev) => setPhone(ev.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                    )}
                <br />
                <br />
                {!isRolandPrivat && (
                    <div>
                        <br />
                        {calendarEvent.was_submitted ? null : (
                            <AddDeviceTask
                                save={() => setSaveNextCycle(true)}
                                name={onGenerateNameClick}
                                eventId={eventId}
                                eventType={eventType?.name}
                            />
                        )}
                        <div>
                            <label>Aufgaben</label>
                            <ListDeviceTasks
                                deviceTasks={tasks}
                                deletable={!calendarEvent.was_submitted}
                                calendarEvent={calendarEvent}
                            />
                        </div>
                    </div>
                )}
                {!isNachbearbeitung && (
                    <>
                        <br />
                        <div style={{ marginBottom: "5px" }}>
                            <label>Name</label>
                        </div>
                        <input
                            value={name}
                            disabled={calendarEvent.was_submitted}
                            style={{ width: "50%" }}
                            onChange={(ev) => setName(ev.target.value)}
                        />
                        <br />
                        <br />
                        {!isRolandPrivat && (
                            <>
                                <label htmlFor="calendar-events-notes">
                                    Bemerkung (z.B. Material oder Ortsangaben)
                                </label>
                                <br />
                                <textarea
                                    id="calendar-events-notes"
                                    className="form-control rounded-0"
                                    rows={10}
                                    placeholder="z.B. Material oder Ortsangaben"
                                    disabled={calendarEvent.was_submitted}
                                    value={notes}
                                    style={{ width: "50%" }}
                                    onChange={(ev) => setNotes(ev.target.value)}
                                ></textarea>
                                <br />
                                <br />

                                <div style={{ marginBottom: "250px" }}>
                                    <RepairBox
                                        // onChange={setRepairBox}
                                        initial={repairBox}
                                        calendarEvent={calendarEvent}
                                        tasks={tasks}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
                <br />
                <Alert
                    style={{
                        position: "fixed",
                        top: "0px",
                        zIndex: 999,
                        left: "0px",
                        right: "0px",
                    }}
                    show={showMaterialAlert}
                    variant="success"
                >
                    <Alert.Heading>Material verfügbar</Alert.Heading>
                    <p>
                        Bitte hinterlassen Sie im Feld "Angaben zum Material und Ort des Materials"
                        nun alle benötigten Informationen zum Material, weisen Sie das Event einem
                        Gerät zu und legen Sie einen Zeitraum für das Event fest.
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button
                            onClick={() => setShowMaterialAlert(false)}
                            variant="outline-success"
                        >
                            Verstanden
                        </Button>
                    </div>
                </Alert>
            </Content>
        </>
    );
};

export default ViewCalendarEventPage;
