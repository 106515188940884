import moment from "moment";
import { toast } from "react-toastify";

export const isWeekday = (date) => date.day() >= 1 && date.day() <= 5;

export const isWeekend = (day) => day === 0 || day === 6;

export const countWeekendDays = (startDay, daysWithWeekends) => {
    const startWeekday = startDay.day();
    let weekendDays = 0;

    for (let i = 0; i < daysWithWeekends; i++) {
        if (isWeekend((startWeekday + i) % 7)) {
            weekendDays++;
        }
    }
    return weekendDays;
};

export const calculateHolidays = (startDay, endDay, holidayArr) => {
    let res = 0;

    const dec24 = moment("12-24", "MM-DD");
    const dec31 = moment("12-31", "MM-DD");

    if (dec24.isBetween(startDay, endDay, null, "[]") && isWeekday(dec24)) {
        res += 0.5;
    }

    if (dec31.isBetween(startDay, endDay, null, "[]") && isWeekday(dec31)) {
        res += 0.5;
    }

    holidayArr.map((holiday) => {
        if (holiday.isBetween(startDay, endDay, null, "[]")) {
            res++;
        }
    });

    return res;
};

class VacationValidator {
    startDate: string;
    endDate: string;
    vacation: any;
    vacations: any;
    residualVacation: boolean;
    residualVacationDays: number;
    freeVacationDays: number;
    vacationDays: number;
    calculatedVacationDays: number;
    nextYearFreeVacationDays: number;

    constructor({
        startDate,
        endDate,
        vacation,
        vacations,
        residualVacation,
        residualVacationDays,
        freeVacationDays,
        vacationDays,
        calculatedVacationDays,
        nextYearFreeVacationDays,
    }: any) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.vacation = vacation;
        this.vacations = vacations;
        this.residualVacation = residualVacation;
        this.residualVacationDays = residualVacationDays;
        this.freeVacationDays = freeVacationDays;
        this.vacationDays = vacationDays;
        this.calculatedVacationDays = calculatedVacationDays;
        this.nextYearFreeVacationDays = nextYearFreeVacationDays;
    }

    validate() {
        return (
            this.validateDateRange() &&
            this.validateNonZeroVacationDays() &&
            this.validateAvailableVacationDays() &&
            this.validateNoOverlap() &&
            this.validateNoCrossYearVacation()
        );
    }

    validateThisYear() {
        return this.validateSufficientResidualVacation();
    }

    validateDateRange() {
        if (!this.startDate || !this.endDate) {
            toast.error("Bitte Zeitraum auswählen.", { autoClose: 2000 });
            return false;
        }
        return true;
    }

    validateNonZeroVacationDays() {
        if (this.calculatedVacationDays === 0) {
            toast.error("Diese Eingabe würde zu einem Urlaub von 0 Tagen führen.", {
                autoClose: 2000,
            });
            return false;
        }
        return true;
    }

    validateAvailableVacationDays() {
        if (this.residualVacation) return true;
        if (
            this.vacation
                ? moment(this.vacation.to).isSameOrBefore(moment().endOf("year")) &&
                  this.freeVacationDays - (this.calculatedVacationDays - this.vacation.days) < 0
                : moment(this.endDate).isSameOrBefore(moment().endOf("year")) &&
                  this.freeVacationDays - this.calculatedVacationDays < 0
        ) {
            toast.error("Dieser Zeitraum überschreitet die verbliebenen Urlaubstage.", {
                autoClose: 2000,
            });
            return false;
        }
        return true;
    }

    validateNoOverlap() {
        const format = "YYYY-MM-DD";
        const startDate = moment(this.startDate).startOf("day");
        const endDate = moment(this.endDate).startOf("day");
        for (let vac of this.vacations) {
            if (this.vacation?.id === vac.id) continue;
            const from = moment(vac.from, format);
            const to = moment(vac.to, format);
            if (
                startDate.isBetween(from, to, null, "[]") ||
                endDate.isBetween(from, to, null, "[]")
            ) {
                toast.error("Der Mitarbeiter hat in diesem Zeitraum bereits Urlaub.", {
                    autoClose: 2000,
                });
                return false;
            }
        }
        return true;
    }

    validateSufficientResidualVacation() {
        if (!this.residualVacation) return true;
        let freeResiduals = this.residualVacationDays;

        if (this.vacation) {
            freeResiduals += this.vacation.days;
        }

        if (this.calculatedVacationDays > freeResiduals) {
            toast.error("Zu wenig Resturlaub vorhanden um Urlaub abzudecken.", { autoClose: 3000 });
            return false;
        }
        return true;
    }

    validateNoCrossYearVacation() {
        if (moment(this.startDate).year() !== moment(this.endDate).year()) {
            toast.error(
                "Bitte teilen Sie jahresübergreifende Urlaube auf. Einen für jedes Jahr des Zeitraums.",
                { autoClose: 3000 }
            );
            return false;
        }
        return true;
    }

    validateNextYear() {
        return (
            this.validateOneYearInAdvance() &&
            (!this.residualVacation
                ? this.validateEnoughVacationForNextYear()
                : this.validateResidualForNextYearNotLargerThanCurrentFreeDays())
        );
    }

    validateOneYearInAdvance() {
        const endOfNextYear = moment().add(1, "years").endOf("year");
        if (
            moment(this.endDate).isAfter(endOfNextYear) ||
            moment(this.startDate).isAfter(endOfNextYear)
        ) {
            toast.error("Bitte tragen Sie Urlaube nur ein Jahr im Voraus ein.", {
                autoClose: 3000,
            });
            return false;
        }
        return true;
    }

    validateEnoughVacationForNextYear() {
        let freeDaysNextYear = this.nextYearFreeVacationDays;

        if (this.vacation) {
            freeDaysNextYear += this.vacation.days;
        }

        if (this.calculatedVacationDays > freeDaysNextYear) {
            toast.error("Es sind nicht genügend Urlaubstage im nächsten Jahr vorhanden.", {
                autoClose: 3000,
            });
            return false;
        }
        return true;
    }

    validateResidualForNextYearNotLargerThanCurrentFreeDays() {
        let freeResidualsForNextYear = this.freeVacationDays;

        if (this.vacation) {
            freeResidualsForNextYear += this.vacation.days;
        }

        console.log("calculated", this.calculatedVacationDays);
        console.log("free", freeResidualsForNextYear);

        if (this.calculatedVacationDays > freeResidualsForNextYear) {
            toast.error(
                "Der Resturlaub für das nächste Jahr kann nicht größer sein als die verbleibenden Urlaubstage.",
                { autoClose: 3000 }
            );
            return false;
        }
        return true;
    }
}

export default VacationValidator;
