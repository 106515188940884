import { getEntityAJAX, useDebounceCallback } from "@rpforms/shared";
import React from "react";
import AsyncSelect from "react-select/async";

interface IAsyncEntitySelectProps {
    onChange: (value: any) => void;
    value: any;
    loading: boolean;
    onLoad?: (value: any) => void;
}

const DEBOUNCE_TIME = 100;
const RESULT_LIMIT = 100;

export const AsyncEntitySelect = ({
    onChange,
    value,
    loading,
    onLoad,
}: IAsyncEntitySelectProps) => {
    const debouncedGetEntityOptions = useDebounceCallback((inputValue: string, resolve: any) => {
        getEntityAJAX(inputValue, RESULT_LIMIT).then((response) => {
            const data = response.data;
            if (onLoad) onLoad(data);
            resolve(
                data.map((xEntity) => ({
                    value: xEntity.id,
                    label: xEntity.name,
                }))
            );
        });
    }, DEBOUNCE_TIME);

    const promiseEntityOptions = (inputValue: string) => {
        return new Promise((resolve) => {
            debouncedGetEntityOptions(inputValue, resolve);
        });
    };

    return (
        <AsyncSelect
            value={value}
            onChange={onChange}
            placeholder={"Objekt auswählen..."}
            cacheOptions
            isDisabled={loading}
            defaultOptions
            loadOptions={promiseEntityOptions}
        />
    );
};
