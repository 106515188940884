import React from "react";
import Select from "react-select";

export function DeviceSelector({ selectableDevices, devices, selectedDevice, setSelectedDevice }) {
    return (
        <div>
            <label>Einem Gerät zuweisen</label>
            <div className="w-50">
                <Select
                    options={selectableDevices}
                    value={selectableDevices.find((e) => e.value === selectedDevice?.id)}
                    defaultValue={selectableDevices.find((e) => e.value === selectedDevice?.id)}
                    onChange={(selected) =>
                        setSelectedDevice(devices.find((d) => d.id === selected.value))
                    }
                    styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                />
            </div>
        </div>
    );
}
