import React from "react";
import { Route, Switch } from "react-router-dom";
import CalendarPage from "./CalendarPage";

const RoutingPage = () => {
    return (
        <>
            <Switch>
                <Route path="/calendar" exact component={CalendarPage} />
                <Route path="/calendar/vacations" exact component={CalendarPage} />
                <Route path="/calendar/planning" exact component={CalendarPage} />
            </Switch>
        </>
    );
};

export default RoutingPage;
