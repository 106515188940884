import { createCalendarEvent, updateCalendarEvent } from "@rpforms/shared/build/actions";
import { getCalendarEventTypes } from "@rpforms/shared/build/actions/calendar_event_types";
import { Content } from "@rpforms/shared/build/components/layout/Content";
import PageNavbar from "@rpforms/shared/build/components/universal/PageNavbar";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button, Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { Route, Switch, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import EventCalendar from "../../components/devices/calendar_events/EventCalendar";
import VacationCalendar from "../../components/employees/VacationCalendar";
import { RootState } from "../../reducers";
import PlanningPage from "./PlanningPage";

const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";

export const CalendarPage = () => {
    const { id }: { id: string } = useParams();
    const deviceId = useLocation().search.split("?")[1]?.split("device=")[1] ?? null;
    const types = useSelector<RootState, any>((store) => store.calendarEventTypes.types);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCalendarEventTypes());
    }, []);

    const [dropdown, setDropdown] = useState(false);

    const onCreateClick = async (t) => {
        const scheduledFrom = moment
            .utc(moment(+new Date()).format("YYYY-MM-DD") + "07:00", "YYYY-MM-DDhh:mm")
            .format(dateFormat);
        const scheduledTo = moment
            .utc(moment(+new Date()).format("YYYY-MM-DD") + "08:00", "YYYY-MM-DDhh:mm")
            .format(dateFormat);
        const eventParams = {
            scheduled_from: scheduledFrom,
            scheduled_to: scheduledTo,
            device_id: deviceId,
            calendar_event_type_id: t?.id || types.find((xt) => xt.name === "Ausführung").id,
        };
        await dispatch(
            id
                ? updateCalendarEvent({ id, ...eventParams }, true)
                : createCalendarEvent(eventParams, true)
        );
        if (id) {
            toast.success("✌️ Event gespeichert", { autoClose: 2000 });
            window.location.href = `/master_data/devices/view-${deviceId}/event-${id}#scroll`;
        }
    };

    const pathEnding = window.location.pathname?.split("/").pop();

    return (
        <>
            <PageNavbar
                title="Terminplaner"
                description="Hier finden Sie eine Übersicht über alle Kalendarevents und eingetragenen Urlaube."
            >
                {pathEnding !== "vacations" && (
                    <CreateEventDropdown
                        types={types}
                        dropdown={dropdown}
                        setDropdown={setDropdown}
                        onCreateClick={onCreateClick}
                    ></CreateEventDropdown>
                )}
                <Tab.Container defaultActiveKey={pathEnding === "calendar" ? "events" : pathEnding}>
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="events" href="/calendar">
                                Events
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="vacations" href="/calendar/vacations">
                                Urlaube
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="planning" href="/calendar/planning">
                                Vorausplanung
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Tab.Container>
            </PageNavbar>

            <Content>
                <Switch>
                    <Route path="/calendar" exact component={EventCalendar} />
                    <Route path="/calendar/vacations" component={VacationCalendar} />
                    <Route path="/calendar/planning" component={PlanningPage} />
                </Switch>
            </Content>
        </>
    );
};

function CreateEventDropdown({
    dropdown,
    setDropdown,
    types,
    onCreateClick,
}: {
    dropdown: boolean;
    setDropdown: Dispatch<SetStateAction<boolean>>;
    types: any;
    onCreateClick: any;
}) {
    return (
        <div
            style={{
                marginRight: "5px",
                position: "relative",
            }}
        >
            <Button onClick={() => setDropdown(!dropdown)}>
                Neues Event <i className={"fa fa-chevron-down"}></i>
            </Button>
            {dropdown && (
                <div
                    style={{
                        position: "absolute",
                        bottom: "-250px",
                        left: 0,
                        background: "#fff",
                        height: "250px",
                        boxShadow: "5px 5px 10px rgba(0,0,0,.1)",
                    }}
                >
                    <p
                        style={{
                            padding: "5px",
                        }}
                    >
                        Wählen Sie einen Eventtypen aus
                    </p>
                    <Nav variant="pills" defaultActiveKey="/calendar/planning">
                        {types.map((t) => {
                            return (
                                <Button
                                    key={`btn${t.name}`}
                                    style={{
                                        width: "100%",
                                        borderRadius: 0,
                                        marginBottom: "3px",
                                    }}
                                    onClick={() => onCreateClick(t)}
                                >
                                    {t.name}
                                </Button>
                            );
                        })}
                    </Nav>
                </div>
            )}
        </div>
    );
}

export default withRouter(CalendarPage);
