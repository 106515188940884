import { EDITOR_SAVING, toCentsInt, toSimpleCurrencyString } from "@rpforms/shared";
import { createCategoryAJAX, createManufacturerAJAX } from "@rpforms/shared/build/actions";
import { PrimaryButton } from "@rpforms/shared/build/components/Button";
import { InputError } from "@rpforms/shared/build/components/layout/Widget";
import React, { useState } from "react";
import { Col, Dropdown, InputGroup, Modal, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-masked-input";
import useForm from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CategorySelect } from "../categories/select";
import { ManufacturerSelect } from "../manufacturers/select";

interface IModalInventoryItem {
    editor: IEditor;
    next: (payload) => {};
}

export const ModalInventoryItem = ({ editor, next }: IModalInventoryItem) => {
    const dispatch = useDispatch();
    const [extraArgs, setExtraArgs] = useState<any>({
        sales_price: 0,
        buying_price: 0,
        ...editor.config.preload,
    });
    const [manufacturer, setManufacturer] = useState({
        value: editor.config.preload.manufacturer_id,
        label: editor.config.preload.manufacturer_name,
    });
    const [category, setCategory] = useState({
        value: editor.config.preload.category_id,
        label: editor.config.preload.category_name,
    });
    const [inventoryItem, setInventoryItem] = useState<any>({ ...editor.config.preload });
    const { register, handleSubmit, watch, errors } = useForm();

    const onSubmit = async (data) => {
        const response = await next({
            inventory_item: {
                ...editor.config.preload,
                ...extraArgs,
                ...data,
                inventory_type: inventoryItem.inventory_type,
                category_id: category.value,
                manufacturer_id: manufacturer.value,
            },
        });
        if (response) {
            if (editor.config.submitCallback) {
                editor.config.submitCallback(response);
            } else {
                window.location.reload(true);
            }
        } else {
            toast.error("Ein Fehler ist aufgetreten, bitte prüfen Sie Ihre Eingaben", {
                autoClose: 3000,
            });
            dispatch({ type: EDITOR_SAVING, payload: false });
        }
    };

    const createManufacturer = async (inputValue: any) => {
        const created: any = await dispatch(createManufacturerAJAX({ name: inputValue }));
        setManufacturer({
            value: created.id,
            label: created.name,
        });
    };

    const updateManufacturer = (newValue: any, actionMeta: any) => {
        setManufacturer(newValue);
    };

    const createCategory = async (inputValue: any) => {
        const created: any = await dispatch(createCategoryAJAX({ name: inputValue }));
        setCategory({
            value: created.id,
            label: created.name,
        });
    };

    const updateCategory = (newValue: any, actionMeta: any) => {
        setCategory(newValue);
    };

    const updateSalesPrice = (event, maskedvalue) => {
        const valueInCents = toCentsInt(maskedvalue);
        setExtraArgs({ ...extraArgs, sales_price: valueInCents });
    };

    const updateBuyingPrice = (event, maskedvalue) => {
        const valueInCents = toCentsInt(maskedvalue);
        setExtraArgs({ ...extraArgs, buying_price: valueInCents });
    };

    const updateDescription = (event) => {
        setExtraArgs({ ...extraArgs, description: event.target.value });
    };

    const updateNr = (event) => {
        setExtraArgs({ ...extraArgs, nr: event.target.value });
    };

    const isQuantifiable = () => {
        return (
            inventoryItem.inventory_type === "repair" || inventoryItem.inventory_type === "disposal"
        );
    };

    const isValid =
        (isQuantifiable() &&
            category.value &&
            manufacturer.value &&
            extraArgs.sales_price > 0 &&
            extraArgs.buying_price > 0) ||
        extraArgs.sales_price > 0;
    const isNew = editor.config.new;

    const title = () => {
        let result = "Neue Ware erstellen";
        if (inventoryItem.inventory_type === "repair") {
            result = isNew ? "Neue Ware erstellen" : "Ware bearbeiten";
        } else if (inventoryItem.inventory_type === "service") {
            result = isNew ? "Neue Dienstleistung erstellen" : "Dienstleistung bearbeiten";
        } else if (inventoryItem.inventory_type === "disposal") {
            result = isNew ? "Neue Verkaufspostion erstellen" : "Verkaufspostion bearbeiten";
        }
        return result;
    };

    const inventoryItemTypes = {
        repair: "Reparatur",
        disposal: "Verkauf",
        service: "Service",
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{title()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <input
                            type="hidden"
                            name="inventory_type"
                            defaultValue={inventoryItem.inventory_type}
                        />
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                {inventoryItemTypes[inventoryItem.inventory_type]}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {Object.keys(inventoryItemTypes).map((inventoryType) => {
                                    return (
                                        <Dropdown.Item
                                            onClick={() => {
                                                setInventoryItem({
                                                    ...inventoryItem,
                                                    inventory_type: inventoryType,
                                                });
                                            }}
                                            key={"inventory_type_" + inventoryType}
                                        >
                                            {inventoryItemTypes[inventoryType]}
                                        </Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    {inventoryItem.inventory_type === "disposal" && (
                        <Col xs={2}>
                            <label>Artikel-Nr</label>
                            <input
                                defaultValue={inventoryItem.nr}
                                className={"form-control"}
                                onChange={updateNr}
                                name="nr"
                            />
                            {errors.nr && <InputError>{errors.nr.message}</InputError>}
                        </Col>
                    )}
                    {inventoryItem.inventory_type === "disposal" && (
                        <Col>
                            <label>Hersteller</label>
                            <ManufacturerSelect
                                value={manufacturer}
                                onChange={updateManufacturer}
                                onCreateOption={createManufacturer}
                                name="manufacturer_id"
                            />
                            {!manufacturer.value && (
                                <InputError>Bitte Hersteller ausfüllen</InputError>
                            )}
                        </Col>
                    )}
                    {isQuantifiable() && (
                        <Col>
                            <label>Gewerk</label>
                            <CategorySelect
                                value={category}
                                onChange={updateCategory}
                                onCreateOption={createCategory}
                                name="category_id"
                            />
                            {!category.value && <InputError>Bitte Gewerk ausfüllen</InputError>}
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <label>Name</label>
                        <input
                            defaultValue={inventoryItem.name}
                            className={"form-control"}
                            name="name"
                            ref={register({
                                required: "Name muss ausgefüllt sein",
                            })}
                        />
                        {errors.name && <InputError>{errors.name.message}</InputError>}
                    </Col>
                    {isQuantifiable() && (
                        <Col xs={2}>
                            <label>Anzahl</label>
                            <input
                                type="number"
                                defaultValue={inventoryItem.quantity}
                                className={"form-control"}
                                name="quantity"
                                ref={register({
                                    required: "Anzahl muss ausgefüllt sein",
                                })}
                            />
                            {errors.quantity && <InputError>{errors.quantity.message}</InputError>}
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <label>Beschreibung</label>
                        <textarea
                            className={"form-control"}
                            name="description"
                            defaultValue={inventoryItem.description}
                            onChange={updateDescription}
                        />
                    </Col>
                </Row>
                <Row>
                    {inventoryItem.inventory_type === "disposal" && (
                        <Col>
                            <label>Einkaufspeis</label>
                            <InputGroup>
                                <CurrencyInput
                                    defaultValue={
                                        inventoryItem.buying_price
                                            ? toSimpleCurrencyString(inventoryItem.buying_price)
                                            : "0.0"
                                    }
                                    className={"form-control"}
                                    onChange={updateBuyingPrice}
                                    name="buying_price"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>€</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                            {!extraArgs.buying_price ||
                                (extraArgs.buying_price <= 0 && (
                                    <InputError>Einkaufspreis muss größer als 0 sein.</InputError>
                                ))}
                        </Col>
                    )}
                    <Col>
                        <label>Verkaufspreis</label>
                        <InputGroup>
                            <CurrencyInput
                                defaultValue={
                                    inventoryItem.sales_price
                                        ? (inventoryItem.sales_price / 100).toString()
                                        : "0.0"
                                }
                                className={"form-control"}
                                onChange={updateSalesPrice}
                                name="sales_price"
                            />
                            <InputGroup.Append>
                                <InputGroup.Text>€</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                        {!extraArgs.sales_price ||
                            (extraArgs.sales_price <= 0 && (
                                <InputError>Verkaufspreis muss größer als 0 sein.</InputError>
                            ))}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {editor.saving && <>speichert …</>}
                <PrimaryButton
                    disabled={!isValid || editor.saving}
                    style={editor.saving ? { background: "gray" } : {}}
                    type={"submit"}
                >
                    {isNew ? "Erstellen" : "Speichern"}
                </PrimaryButton>
            </Modal.Footer>
        </form>
    );
};
